import React from "react";
import PropTypes from "prop-types";
import CompanyExcludedHCPCS, {
  useCompanyExcludedHCPCS
} from "./excluded-hcpcs";

const HcpcHistory = ({ companyId }) => {

  const excludedHcpcs = useCompanyExcludedHCPCS(companyId);

  return (
        <div>
          <CompanyExcludedHCPCS
            {...companyId}
            {...excludedHcpcs}
          />
      </div>
  );
};

HcpcHistory.propTypes = {
    companyId: PropTypes.string.isRequired
};

export default HcpcHistory;