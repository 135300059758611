import React from "react";
import PropTypes from "prop-types";
import { ToggleSwitch, InlineWaiting } from "components/ui";
import { get, put } from "utils/api";
import { useFetch } from "hooks";
import { createReducer } from "utils/reducer";
import * as R from "ramda";

const Types = {
  SET_EXCLUDED_HCPCS: "SET_EXCLUDED_HCPCS",
  UPDATE_EXCLUDED_HCPCS: "UPDATE_EXCLUDED_HCPCS",
  UPDATE_ORIGIN_HCPCS: "UPDATE_ORIGIN_HCPCS"
};

const patientExcludedHCPCSReducer = createReducer(
  {},
  {
    [Types.SET_EXCLUDED_HCPCS]: (_, { hcpcs }) =>
      R.indexBy(R.prop("hcpc_code"), hcpcs),
    [Types.UPDATE_EXCLUDED_HCPCS]: (state, { hcpc }) =>
      R.assocPath([hcpc.hcpc_code, "excluded"], hcpc.excluded, state),
    [Types.UPDATE_ORIGIN_HCPCS]: (state, { hcpc }) =>
      R.assocPath([hcpc.hcpc_code, "origin"], hcpc.origin, state)
  }
);

export const usePatientExcludedHCPCS = patientId => {
  const [excludedHcpcsState, excludedHcpcsDispatche] = React.useReducer(
    patientExcludedHCPCSReducer,
    []
  );

  const getPatientExcludedHCPCsAPI = useFetch({
    defaultValue: [],
    apiFn: patientId => get(`patients/${patientId}/excluded-hcpcs`),
    onSuccess: hcpcs => {
      excludedHcpcsDispatche({ type: Types.SET_EXCLUDED_HCPCS, hcpcs });
    }
  });
  const updatePatientExcludedHCPCsAPI = useFetch({
    apiFn: values => put(`patients/${patientId}/excluded-hcpcs`, values),
    onSuccess: hcpc => {
      excludedHcpcsDispatche({ type: Types.UPDATE_EXCLUDED_HCPCS, hcpc });
      excludedHcpcsDispatche({ type: Types.UPDATE_ORIGIN_HCPCS, hcpc });
    }
  });

  React.useEffect(() => {
    getPatientExcludedHCPCsAPI.fetchData(patientId);
  }, [patientId]);

  const excludedHcpcs = Object.groupBy(
    R.values(excludedHcpcsState),
    ({ productType }) => productType
  );
  const excludedHcpcsCount =
    R.values(excludedHcpcsState)?.filter(x => x.excluded).length ?? "";
  return {
    excludedHcpcs,
    excludedHcpcsCount,
    isFetching: getPatientExcludedHCPCsAPI.isFetching,
    updatePatientExcludedHCPCs: updatePatientExcludedHCPCsAPI.fetchData
  };
};

const PatientExcludedHCPCS = ({
  excludedHcpcs,
  isFetching,
  updatePatientExcludedHCPCs
}) => {
  return (
    <div style={{ margin: 10 }}>
      <h1>Excluded HCPCS</h1>
      {isFetching ? (
        <InlineWaiting />
      ) : (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          {Object.keys(excludedHcpcs).map(x => (
            <div key={x}>
              <h3
                style={{
                  display: "flex",
                  flex: 1
                }}
              >
                {x}
              </h3>
              <div>
                {excludedHcpcs[x].map(hcpc => (
                  <ExcludedHCPCRow
                    key={hcpc.hcpc_id}
                    {...hcpc}
                    updatePatientExcludedHCPCs={updatePatientExcludedHCPCs}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

PatientExcludedHCPCS.propTypes = {
  excludedHcpcs: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  updatePatientExcludedHCPCs: PropTypes.func.isRequired
};

const ExcludedHCPCRow = ({
  hcpc_id,
  excluded,
  hcpc_code,
  origin,
  updatePatientExcludedHCPCs
}) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  return (
    <div
      key={hcpc_id}
      style={{
        display: "grid",
        padding: 5,
        fontSize: 18,
        gridTemplateColumns: "auto",
        gridGap: 10
      }}
    >
      <ToggleSwitch
        // disabled={origin.includes("company") || origin.includes("insurance")}
        disabled={(origin.includes("company") || origin.includes("insurance")) && !origin.includes("patient")}
        // title={(origin.includes("company") || origin.includes("insurance")) ? "DISABLED" : ""}
        title={(origin.includes("company") || origin.includes("insurance")) && !origin.includes("patient") ? "DISABLED" : ""}
        label={hcpc_code}
        value={excluded}
        isUpdating={isUpdating}
        onChange={async x => {
          try {
            setIsUpdating(true);
            await updatePatientExcludedHCPCs({
              hcpc_id: hcpc_id,
              hcpc_code: hcpc_code,
              excluded: x
            });
          } finally {
            setIsUpdating(false);
          }
        }}
      />
      <div style={{ marginLeft: "1rem", fontSize: "1rem" }}>{`${origin.map(x => x)}`}</div>
    </div>
  );
};

ExcludedHCPCRow.propTypes = {
  hcpc_id: PropTypes.string.isRequired,
  excluded: PropTypes.bool.isRequired,
  hcpc_code: PropTypes.string.isRequired,
  origin: PropTypes.arrayOf(PropTypes.string).isRequired,
  updatePatientExcludedHCPCs: PropTypes.func.isRequired
};

export default PatientExcludedHCPCS;
