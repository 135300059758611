import React, { useEffect, useState, useContext } from "react";
import { HcpcEligibilityTableRow } from "./hcpc-display";
import InsuranceCompanyTabs from "./insurance-company-info";
import InsuranceCompanyDisplay from "./insurance-company-display";
import { InsuranceCompanyContext } from "./insurance-company-provider";
import { Waiting } from "components/ui";
import InsuranceExcludedHcpc from "./excluded-index";

export const HCPCEligibilityTable = () => {
  const {
    selectedInsurance,
    getHCPCs,
    UpdateHCPCEligibility,
    companyGuid,
    isFetchingHcpcs
  } = useContext(InsuranceCompanyContext);
  const hcpcQuery = { insurance: selectedInsurance, guid: companyGuid };

  const [editAllowable, setEditAllowable] = useState("");
  useEffect(() => {
    if (selectedInsurance != "") {
      getHCPCs(hcpcQuery);
    }
  }, [selectedInsurance]);

  const [editableFields, setEditableFields] = useState("");
  const [selectedTab, setSelectedTab] = useState("company-info");
  const ToggleEditability = hcpc => {
    if (editableFields == hcpc) {
      setEditableFields("");
    } else {
      setEditableFields(hcpc);
    }
  };
  const handleSubmit = formData => {
    const payload = formData;
    payload.insurance_company = selectedInsurance;
   UpdateHCPCEligibility(payload);
    setEditableFields("");
  };
  if (!selectedInsurance) {
    return null;
  } else {
    return (
      <div>
        <InsuranceCompanyTabs
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
        />
        {isFetchingHcpcs ? (
          <Waiting size="50px" style={{ position: "sticky" }}/>
        ) : (
          <div>
            {selectedTab === "company-info" && <InsuranceCompanyDisplay companyGuid={companyGuid} />}
            {selectedTab === "line-item-eligibility" && 
              <HcpcEligibilityTableRow
                handleSubmit={handleSubmit}
                editAllowable={editAllowable}
                setEditAllowable={setEditAllowable}
                editableFields={editableFields}
                setEditableFields={setEditableFields}
                ToggleEditability={ToggleEditability}
              />}
            {selectedTab === "excluded-hcpcs" && <InsuranceExcludedHcpc companyId={companyGuid} insuranceId={selectedInsurance} />}
          </div>
        )}
      </div>
    );
  }
};
