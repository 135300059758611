import React from "react";
import PropTypes from "prop-types";
import InsuranceExcludedHCPCS, {
    useInsuranceExcludedHCPCS
} from "./excluded-hcpcs";

const InsuranceExcludedHcpc = ({ insuranceId }) => {
  const excludedHcpcs = useInsuranceExcludedHCPCS(insuranceId);
  return (
        <div>
          <InsuranceExcludedHCPCS
            {...insuranceId}
            {...excludedHcpcs}
          />
      </div>
  );
};

InsuranceExcludedHcpc.propTypes = {
    insuranceId: PropTypes.string.isRequired
};

export default InsuranceExcludedHcpc;