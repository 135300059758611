import React, { useContext } from "react";
import PropTypes from "prop-types";
import { InsuranceCompanyContext } from "./insurance-company-provider";
import HasRolePermission from "components/shared/has-role-permission";

const InsuranceCompanyTabs = ({ setSelectedTab, selectedTab }) => {
  const { hcpcs, isFetchingHcpcs } = useContext(InsuranceCompanyContext);
  return (
    <>
      <div
        style={{
          textAlign: "center",
          fontSize: "2.5em",
          fontWeight: "bold",
          minHeight: 65,
          color: "#666666"
        }}
      >
        {!isFetchingHcpcs &&
          hcpcs &&
          hcpcs.prefix + "_" + hcpcs.sInsuranceCompanyName}
      </div>
      <div style={{ display: "flex" }}>
        <div
          className={`insurance-company-tab ${
            selectedTab === "company-info" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("company-info")}
        >
          Company Info
        </div>
        <div
          className={`insurance-company-tab ${
            selectedTab === "line-item-eligibility" ? "active" : ""
          }`}
          onClick={() => setSelectedTab("line-item-eligibility")}
        >
          Line Item Eligibility
        </div>
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            // "CompanyAdministrator",
            // "OfficeAdministrator",
            // "ServiceCoach",
            // "ServiceAdmin",
            // ,'ContractorAdministrator'
          ]}>
          <div
            className={`insurance-company-tab ${
              selectedTab === "excluded-hcpcs" ? "active" : ""
            }`}
            onClick={() => setSelectedTab("excluded-hcpcs")}
          >
            Excluded HCPCS
          </div>
        </HasRolePermission>
      </div>
    </>
  );
};

export default InsuranceCompanyTabs;
InsuranceCompanyTabs.propTypes = {
  hcpcs: PropTypes.object,
  setSelectedTab: PropTypes.func,
  selectedTab: PropTypes.string
};
