import React from "react";
import PropTypes from "prop-types";
import { ToggleSwitch, InlineWaiting } from "components/ui";
import { get, put } from "utils/api";
import { useFetch } from "hooks";
import { createReducer } from "utils/reducer";
import * as R from "ramda";

const Types = {
  SET_COMPANY_EXCLUDED_HCPCS: "SET_COMPANY_EXCLUDED_HCPCS",
  UPDATE_COMPANY_EXCLUDED_HCPCS: "UPDATE_COMPANY_EXCLUDED_HCPCS",
  UPDATE_COMPANY_ORIGIN_HCPCS: "UPDATE_COMPANY_ORIGIN_HCPCS"
};

const companyExcludedHCPCSReducer = createReducer(
  {},
  {
    [Types.SET_COMPANY_EXCLUDED_HCPCS]: (_, { hcpcs }) =>
      R.indexBy(R.prop("hcpc_code"), hcpcs),
    [Types.UPDATE_COMPANY_EXCLUDED_HCPCS]: (state, { hcpc }) =>
      R.assocPath([hcpc.hcpc_code, "excluded"], hcpc.excluded, state),
    [Types.UPDATE_COMPANY_ORIGIN_HCPCS]: (state, { hcpc }) =>
      R.assocPath([hcpc.hcpc_code, "origin"], hcpc.origin, state)
  }
);

export const useCompanyExcludedHCPCS = companyId => {
  const [excludedHcpcsState, excludedHcpcsDispatche] = React.useReducer(
    companyExcludedHCPCSReducer,
    []
  );

  const getCompanyExcludedHCPCsAPI = useFetch({
    defaultValue: [],
    apiFn: companyId => get(`companies/${companyId}/excluded-hcpcs`),
    onSuccess: hcpcs => {
      excludedHcpcsDispatche({ type: Types.SET_COMPANY_EXCLUDED_HCPCS, hcpcs });
    }
  });
  const updateCompanyExcludedHCPCsAPI = useFetch({
    apiFn: values => put(`companies/${companyId}/excluded-hcpcs`, values),
    onSuccess: hcpc => {
      excludedHcpcsDispatche({ type: Types.UPDATE_COMPANY_EXCLUDED_HCPCS, hcpc });
      excludedHcpcsDispatche({ type: Types.UPDATE_COMPANY_ORIGIN_HCPCS, hcpc });
    }
  });

  React.useEffect(() => {
    getCompanyExcludedHCPCsAPI.fetchData(companyId);
  }, [companyId]);

  const excludedHcpcs = Object.groupBy(
    R.values(excludedHcpcsState),
    ({ productType }) => productType
  );
  const excludedHcpcsCount =
    R.values(excludedHcpcsState)?.filter(x => x.excluded).length ?? "";
  return {
    excludedHcpcs,
    excludedHcpcsCount,
    isFetching: getCompanyExcludedHCPCsAPI.isFetching,
    updateCompanyExcludedHCPCs: updateCompanyExcludedHCPCsAPI.fetchData
  };
};

const CompanyExcludedHCPCS = ({
  excludedHcpcs,
  isFetching,
  updateCompanyExcludedHCPCs
}) => {
  return (
    <div style={{ margin: 10 }}>
      <h1>Excluded HCPCS</h1>
      {isFetching ? (
        <InlineWaiting />
      ) : (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          {Object.keys(excludedHcpcs).map(x => (
            <div key={x}>
              <h3
                style={{
                  display: "flex",
                  flex: 1
                }}
              >
                {x}
              </h3>
              <div>
                {excludedHcpcs[x].map(hcpc => (
                  <ExcludedHCPCRow
                    key={hcpc.hcpc_id}
                    {...hcpc}
                    updateCompanyExcludedHCPCs={updateCompanyExcludedHCPCs}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CompanyExcludedHCPCS.propTypes = {
  excludedHcpcs: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  updateCompanyExcludedHCPCs: PropTypes.func.isRequired
};

const ExcludedHCPCRow = ({
  hcpc_id,
  excluded,
  hcpc_code,
  updateCompanyExcludedHCPCs
}) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  return (
    <div
      key={hcpc_id}
      style={{
        display: "grid",
        padding: 5,
        fontSize: 18,
        gridTemplateColumns: "auto",
        gridGap: 10
      }}
    >
      <ToggleSwitch
        label={hcpc_code}
        value={excluded}
        isUpdating={isUpdating}
        onChange={async x => {
          try {
            setIsUpdating(true);
            await updateCompanyExcludedHCPCs({
              hcpc_id: hcpc_id,
              hcpc_code: hcpc_code,
              excluded: x
            });
          } finally {
            setIsUpdating(false);
          }
        }}
      />
    </div>
  );
};

ExcludedHCPCRow.propTypes = {
  hcpc_id: PropTypes.number.isRequired,
  excluded: PropTypes.bool.isRequired,
  hcpc_code: PropTypes.string.isRequired,
  updateCompanyExcludedHCPCs: PropTypes.func.isRequired
};

export default CompanyExcludedHCPCS;